import { ReferralApi } from '@/services'
import moment from 'moment'
import ToothList from '@/constants/toothlist'
//!!! This is imported for the getStatus function due to the sort function on the referrals. Really, the status should be normalized to the object in the store.
// this i workaround for now
import _store from '@/store'
import * as Sentry from '@sentry/vue'

const STATUSES = {
  'Not Sent': 0,
  Referred: 1,
  'Referred - Manual': 2,
  Scheduling: 3,
  Scheduled: 4,
  'In Progress': 5,
  Cancelled: 6,
  Completed: 7,
  Archived: 8
}

export default {
  copyReferral: function(referral, store, router, $dialog) {
    const copyKeys = [
      'patient_firstname',
      'patient_middlename',
      'patient_lastname',
      'patient_phone',
      'patient_dob',
      'patient_email',
      'guardian_name',
      'availibility',
      'preferred_contact_method',
      'patient_insurance_network',
      'patient_insurance_membership',
      'patient_insurance_subscriber',
      'patient_insurance_network_secondary',
      'patient_insurance_membership_secondary',
      'patient_insurance_subscriber_secondary',
      'is_patient_opted_in'
    ]

    let copy = copyKeys.reduce((o, k) => {
      o[k] = referral[k]
      return o
    }, {})
    const redirect = () => router.push(`/referral?data=${btoa(JSON.stringify(copy))}`)

    if (!referral.is_incoming) redirect()
    else {
      $dialog
        .confirm(
          {
            title: 'Refer Back',
            body: `Would you like to refer back to ${referral.practice.name}?`
          },
          { okText: 'Yes', cancelText: 'No' }
        )
        .then(() => {
          copy.to_practice_id = referral.practice.id
          copy.to_practice = referral.practice
          redirect()
        })
        .catch(redirect)
    }
  },
  closeReferral: function(referral, prop, title, value, beforeSave, $dialog) {
    const _this = this
    return $dialog
      .prompt({
        title,
        body: 'Enter an optional note and then click "Continue"',
        promptHelp: '', //bug: this plugin does not seem to respect this option, hiding via css and using body text
        okText: title //bug: this isn't respected either by the plugin
      })
      .then(async dialog => {
        const note = dialog.data
        referral[prop] = typeof value === 'undefined' ? moment.utc() : value
        if (typeof beforeSave === 'function') await beforeSave(referral, dialog)
        _store.dispatch('referrals/save', referral).then(() => {
          if (note)
            ReferralApi.saveRelated(referral.id, 'history', {
              details: note,
              referral_id: referral.id
            })
        })

        if (prop === 'cancelled_time' || prop === 'completed_time')
          _this.info(
            `The referral for ${referral.patient_firstname} ${referral.patient_lastname} has been ${
              prop === 'cancelled_time' ? 'cancelled' : 'completed'
            }`
          )
      })
  },
  completeReferral: function(referral, $dialog) {
    const requireFollowUp = async r => {
      //if (r.to_practice.status !== 1) return Promise.resolve(r);
      return $dialog
        .confirm(
          {
            title: 'Require Follow Up?',
            body: `Would you like ${r.practice.name} to provide follow up treatment?`
          },
          { okText: 'Yes', cancelText: 'No' }
        )
        .then(() => {
          r.requires_followup = true
        })
        .catch(() => {})
    }
    if (referral.completed_time && referral.requires_followup) {
      referral.followup_completed_time = moment.utc()
      _store.dispatch('referrals/save', referral)
    } else this.closeReferral(referral, 'completed_time', 'Complete Referral', undefined, requireFollowUp, $dialog)
  },
  getStatus: function(referral, ignoreSnoozed) {
    if (!ignoreSnoozed && this.isArchived(referral, _store)) {
      const until = this.isSnoozed(referral, _store)
      if (until) {
        return `Snoozed - ${until.fromNow()}`
      }
      return 'Snoozed'
    }
    return Object.keys(STATUSES)[this.getStatusIndex(referral)]
  },
  getStatusIndex: function(referral) {
    try {
      if (referral.cancelled_time) return STATUSES.Cancelled
      if (!referral.sent_time) return STATUSES['Not Sent']
      //if (!referral.is_important) return STATUSES.Referred
      if (referral.completed_time) return STATUSES.Completed
      if (referral.scheduled_time || referral.is_marked_scheduled) {
        return referral.scheduled_time && moment().diff(referral.scheduled_time) > 0
          ? STATUSES['In Progress']
          : STATUSES.Scheduled
      }
      if (referral.requested_scheduled_time) {
        return STATUSES.Scheduling
      }
      return STATUSES.Referred // + ((referral.to_practice && referral.to_practice.status) != 1 ? ' - Manual' : '');
    } catch (e) {
      Sentry.captureException(e)
    }
  },
  displayUsername: function(user) {
    return (
      (user &&
        (
          (user.first_name && (user.is_doctor ? 'Dr. ' : '') + `${user.first_name} ${user.last_name}`) ||
          user.username ||
          user.email ||
          ''
        ).trim()) ||
      ''
    )
  },
  getMessageText: function(msg, store) {
    msg = (msg && msg.message) || msg
    if (!msg || !msg.sender) throw 'Invalid Message'

    if (msg.sender.uuid === 'REFERA-BOT') {
      return (msg.sender.pid === store.state.auth.user.practice.id && msg.text_practice) || msg.text
    }

    return msg.text
  },
  formatToothList: function(teeth, className = '') {
    if (!teeth || !teeth.length) return ''
    return teeth
      .filter(t => Object.prototype.hasOwnProperty.call(ToothList, t))
      .sort(t => t)
      .map(
        t =>
          `<div class="d-flex tooth-selection-item mb-2"><span class="tooth-number">${t}</span><span class="tooth-name ml-2 text-nowrap ${className ||
            ''}">${ToothList[t].name}</span></div>`
      )
      .join('')
  },
  isSchedulable: function(referral) {
    return !referral.completed_time && !referral.cancelled_time && referral.is_incoming && referral.sent_time
  },
  isCompletable: function(referral) {
    return (
      referral.sent_time &&
      !(
        referral.cancelled_time ||
        referral.completed_time ||
        (referral.to_practice.status === 1 && !referral.is_incoming)
      )
    )
  },
  isFollowupCompletable: function(referral) {
    return (
      referral.completed_time &&
      referral.requires_followup &&
      !referral.followup_completed_time &&
      !referral.is_incoming
    )
  },
  isArchived: function(referral) {
    return referral[`archived_${!referral.is_incoming ? 'from' : 'to'}_time`]
  },
  isSnoozed: function(referral, store) {
    const until = referral[`snooze_until_${!referral.is_incoming ? 'from' : 'to'}_time`]
    if (until) {
      const snooze = moment(until)
      return moment.utc().isBefore(snooze) ? snooze : false
    }
  },
  handleApiError(data, validator, options) {
    const errors = (data && data.errors) || data || {}
    const self = this
    if (typeof errors !== 'object') throw errors
    Object.keys(errors).forEach(key => {
      if (key === 'non_field_errors')
        //NON_FIELD_ERRORS_KEY
        errors[key].forEach(self.error)
      else if (key === 'detail') self.error(errors[key])
      else if (validator) self.setFieldError(key, errors[key], validator, options)
      else {
        self.error(`${key}: ${errors[key]}`)
        console.warn('A field error was returned but no validator was set to handle it', errors[key])
        Sentry.captureException(data)
      }
    })
  },
  setFieldError(field, errors, validator, options) {
    if (!validator) {
      console.warn('A field error was returned but no validator was set to handle it', errors)
      Sentry.captureException(errors)
      return
    }

    const _field = validator.fields.find({ name: field, scope: options.scope })

    if (!_field) {
      console.warn(
        `Field '${field}' threw ${errors.length} error(s) but the $validator instance did not have a corresponding field.`,
        errors,
        validator
      )
      this.error(`${field}: ${errors?.join(' ')}`)
      return
    }

    errors.forEach(msg => {
      validator.errors.add({ id: _field.id, field, msg, scope: options.scope })
    })

    _field.setFlags({ invalid: true, valid: false, validated: true })
  },
  info(msg, options = {}) {
    _store.dispatch('notifications/info', msg, options)
  },
  error(msg, options = {}) {
    _store.dispatch('notifications/error', msg, options)
  },
  success(msg, options = {}) {
    _store.dispatch('notifications/success', msg, options)
  },
  prompt(title, body, opts = {}) {
    const defaultOptions = {
      cancelText: 'Cancel',
      okText: 'Confirm',
      loader: true
    }
    return new Promise((resolve, reject) => {
      this.$dialog
        .prompt({ title, body }, { ...defaultOptions, ...opts })
        .then(resolve)
        .catch(reject)
    })
  },
  confirm(title, body, okText = 'Yes', cancelText = 'No', options = {}) {
    return new Promise(resolve => {
      this.$dialog
        .confirm({ title, body }, { ...options, okText, cancelText })
        .then(resolve)
        .catch(() => resolve(false))
    })
  },
  invitePrompt({ name }, defaultText, title = `Send an invite to ${name}`) {
    const invitePreview = require('./assets/invite_preview.png')
    return this.prompt(
      title,
      `<p>Once they accept you will be able to use features like chat with them.</p><a href="${invitePreview}" target="_blank">Preview Invite Email</a>`,
      {
        cancelText: "I don't know their email",
        okText: 'Send Invite',
        loader: true,
        promptHelp: 'Please enter their email below and we will send them an invite',
        html: true
      }
    )
  },
  inviteConfirm({ name }, email) {
    const invitePreview = require('./assets/invite_preview.png')
    return this.confirm(
      `Would you like to send an invite to ${name}?`,
      `<p>Once they accept you will be able to use features like chat with them.</p><a href="${invitePreview}" target="_blank">Preview Invite Email</a>`,
      `Send Invite`,
      'Maybe Later',
      {
        loader: true,
        html: true
      }
    )
  },
  join(arr, seperator = ', ') {
    return arr.filter(s => s).join(', ')
  },
  cityStateZip(practice) {
    const zip = practice.zip || ''
    return this.join([practice.city, practice.state]) + ' ' + zip.substring(0, Math.min(5, zip.length))
  },
  address(practice, seperator = ', ') {
    return this.join([practice.address, practice.address_2]) + seperator + this.cityStateZip(practice)
  },
  getInsuranceInformation(key) {
    return ['Provided Below', 'Call Office', 'Call Patient', 'No Insurance'].find(
      item => item.toLowerCase().replace(/\s/g, '') === key
    )
  },
  waitUntil(selector, scope, func = el => el, resolve, reject, timeout = 10) {
    const interval = 100
    let loopCount = 0
    let maxLoops = timeout * (interval / 10)

    // Loops until element exists in DOM or loop times out
    function checkForElement() {
      if (loopCount === maxLoops) {
        loopCount = 0
        return reject('Timed out waiting for element to render')
      }

      let el = scope.querySelector(selector)

      setTimeout(() => {
        if (el && (!func || func(el))) {
          loopCount = 0
          return resolve(el)
        } else {
          loopCount++
          checkForElement()
        }
      }, interval)
    }

    checkForElement()
  },

  // Returns a resolved Promise once the selector returns an element
  // Useful for when we need to perform an action only when an element is in the DOM
  untilElementRendered(selector, scope = document, timeout = 10) {
    return new Promise((resolve, reject) => {
      //start the loop
      return this.waitUntil(selector, scope, resolve, reject, timeout)
    })
  },

  // Returns a resolved Promise once the selector returns an element
  // Useful for when we need to perform an action only when an element is in the DOM
  untilElement(selector, func, scope = document, timeout = 10) {
    return new Promise((resolve, reject) => {
      //start the loop
      return this.waitUntil(selector, scope, func, resolve, reject, timeout)
    })
  },
  loadScript(src, id) {
    const hash = str => (str + '').split('').reduce((p, c) => ((p << 5) - p + c.charCodeAt(0)) | 0, 0)
    id = id || `Script-import-${hash(src)}`
    if (id && document.getElementById(id)) return Promise.resolve()
    return new Promise((res, rej) => {
      const script = document.createElement('script')
      script.id = id
      script.src = src
      script.onload = res
      script.onerror = rej
      document.head.appendChild(script)
    })
  },
  hslToHex(h, s, l) {
    h /= 360
    s /= 100
    l /= 100
    let r, g, b
    if (s === 0) {
      r = g = b = l // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1
        if (t > 1) t -= 1
        if (t < 1 / 6) return p + (q - p) * 6 * t
        if (t < 1 / 2) return q
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
        return p
      }
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s
      const p = 2 * l - q
      r = hue2rgb(p, q, h + 1 / 3)
      g = hue2rgb(p, q, h)
      b = hue2rgb(p, q, h - 1 / 3)
    }
    const toHex = x => {
      const hex = Math.round(x * 255).toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
  },
  debounce(func, wait = 100) {
    let timeout
    return function(...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        func.apply(this, args)
      }, wait)
    }
  },
  phoneToContactId: phone => (phone.startsWith('+') ? '+' : '+1') + phone.replace(/\D/g, ''),
  referralContactId(r) {
    return r.patient_phone && this.phoneToContactId(r.patient_phone)
  },
  referralContactMatch: r => p => p && this.referralContactId(r) === p,
  calDate: date =>
    moment(date)
      .calendar(null, {
        lastDay: '[Yesterday]',
        sameDay: '[Today] [at] h:mm A',
        nextDay: '[Tomorrow] [at] h:mm A',
        nextWeek: '[next] dddd [at] h:mm A',
        sameElse: 'ddd Do MMM [at] h:mm A'
      })
      .replace(':00', ''),
  cdnUrl: url => (url && url.startsWith('/') ? `${process.env.VUE_APP_API_BASE_URL}${url}` : url),
  doctor: r => `Dr. ${r?.referring_doctor?.first_name?.[0]} ${r?.referring_doctor?.last_name}`.trim()
}
